
.sidebar {
  height: 100vh;
  min-height: 500px;
  width: 25%;
  position: absolute;
  top: 15%;
  left: 0;
  background-color: white;
  overflow: scroll;
  transition: width 0.2s;
}
.sidebar nav {
  padding-left: 50px;
}
.sidebar nav ul {
  display: flex;
  flex-direction: column;
}
.sidebar nav ul h2:hover {
  cursor: pointer;
}
.sidebar nav ul li {
  padding: 10px;
}
.sidebar nav ul li a {
  color: black;
  text-decoration: none;
}
.sidebar nav ul li:hover {
  background-color: whitesmoke;
}
.sidebar nav ul .active {
  display: none;
}
.sidebar nav ul .gettingStarted {
  display: none;
}
.sidebar nav ul .components {
  display: none;
}
.sidebar nav ul .content {
  display: none;
}
.sidebar nav ul .helper {
  display: none;
}
.sidebar nav ul .custom {
  display: none;
}
.sidebar nav ul .guide {
  display: none;
}
.sidebar nav ul .style {
  display: none;
}
