header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f2f2;
    padding: 0 2%;
    box-shadow: 2px 22px 25px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 8rem;
}

.social {
    display: flex;
}

.social li {
    font-size: 2.5rem;
    padding: 0.5rem;
}

.social li i {
    color: #708daa;
}

nav ul {
    display: flex;
}

nav ul .line {
    color: gray;
    font-size: 2.5rem;
    opacity: 0.5;
}

nav ul li {
    color: black;
    text-shadow: 0.2px 0.2px #f53537;
    line-height: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1.5rem;
}

nav ul li a {
    color: black;
    text-decoration: none;
}

.logo-section {
    display: flex;
    align-items: center;
}

.logo-section .logo {
    height: 8rem;
}

.logo-section svg {
    font-size: 2rem;
}